import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';

import styles from './footer.module.scss';
import useLanguage from '../_hooks/useLanguage';
import useAuth from '../_hooks/useAuth';
import useUser from '../_hooks/useUser';
import useConfig from '../_hooks/useConfig';
import { useStore as appStore } from '../../contexts/app';
import Access from '../auth/access';
import SubmenuProfile from '../navigation/submenu/profile';

const Footer = () => {
	const { t } = useLanguage();
	const { authData, openQuickView } = useAuth();
	const { paidAccess } = useUser();
	const { clientConfig } = useConfig();
	const { authenticated } = authData;

	const { dispatch: appDispatch } = appStore();

	const [profileActive, setProfileActive] = useState(false);

	const router = useRouter();

	const resetOnToggle = () => {
		if (profileActive) setProfileActive(false);
	};

	// Handle profile submenu open
	useEffect(() => appDispatch({ type: 'SET_OVERLAY_PANEL', payload: profileActive ? { name: 'userNavigation' } : null }), [profileActive]);

	return (
		<div className={`${styles.toolbar} d-flex d-lg-none`}>
			<div>
				<Link href="/"><a className={router.pathname === '/' ? styles.active : ''} onClick={() => resetOnToggle()}><i className="icon home" />{t('navHome')}</a></Link>
				{clientConfig('GENERAL_LIST') && <Link href="/drazbe"><a className={router.pathname.includes('drazbe') ? styles.active : ''} onClick={() => resetOnToggle()}><i className="icon magnifier" />{t('navSearch')}</a></Link>}
				{clientConfig('ITEM_SAVE') && <button type="button" onClick={() => { paidAccess('/profil/shranjeno'); resetOnToggle(); }} className={router.pathname.includes('shranjeno') ? styles.active : ''}><i className="icon heart-solid light" />{t('navSaved')}</button>}
				<Access
					yes={() => <button type="button" onClick={() => setProfileActive(!profileActive)} className={profileActive ? styles.active : ''}><i className="icon profile" />{t('navProfile')}</button>}
					no={() => <button type="button" onClick={() => openQuickView('login', () => setProfileActive(!profileActive))}><i className="icon profile" />{t('navProfile')}</button>}
				/>
			</div>
			{profileActive && authenticated && <SubmenuProfile title={t('navProfile')} onClose={() => setProfileActive(false)} />}
		</div>
	);
};

export default Footer;
