import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Navigation from '../navigation';
import useLanguage from '../../_hooks/useLanguage';
import useAuth from '../../_hooks/useAuth';
import useApp from '../../_hooks/useApp';
import useProfile from '../../_hooks/useProfile';
import useUser from '../../_hooks/useUser';
import useSiid from '../../_hooks/useSiid';
import useConfig from '../../_hooks/useConfig';

const SubmenuProfile = ({ title, onClose }) => {
	const { logout, logoutIfTokenInvalid } = useAuth();
	const { t } = useLanguage();
	const { getSubmenutCnt, result } = useProfile();
	const { appDispatch } = useApp();
	const { userData, isMember, expireDtmFormated } = useUser();
	const { header } = useSiid();
	const { clientConfig } = useConfig();

	// Check, if administrator
	const [isAdministrator, setIsAdministrator] = useState(false);

	useEffect(() => setIsAdministrator(userData.isadministrator === true), [userData.isadministrator]);

	const [cnt, setCnt] = useState({});
	const [list, setList] = useState([]);

	useEffect(() => {
		getSubmenutCnt();

		const helpEnabled = clientConfig('HELP_ENABLED');

		// Handle list
		setList([...header.navProfile,
			helpEnabled ? { href: null, text: t('navHelp'), type: 'button', onClick: () => appDispatch({ type: 'SUPPORT_CHAT_TOGGLE'}) } : null,
			helpEnabled ? { type: 'separator' } : null,
			{ href: '/odjava', text: t('navLogout'), type: 'button', onClick: () => logout(), classes: 'color-danger' },
			clientConfig('PRO_LOCKED') && isMember ? { type: 'text', text: `${t('labelExpirePro')}: ${expireDtmFormated()}`, classes: 'smaller' } : null
		].map((row) => {
			return (row !== null && (typeof row.adminOnly === 'undefined' || (row.adminOnly && isAdministrator) || (row.nonAdminOnly && !isAdministrator))) ? row : null;
		}));
	}, [isAdministrator]);

	// Counters
	useEffect(() => {
		if (result) setCnt(result)
	}, [result]);

	return (
		<Navigation
			title={title}
			onClose={onClose}
			list={list}
			data={{ cnt }}
		/>
	);
};

SubmenuProfile.defaultProps = {
	title: null,
	onClose: null
};

SubmenuProfile.propTypes = {
	title: PropTypes.string,
	onClose: PropTypes.func
};

export default SubmenuProfile;
