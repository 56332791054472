import { useEffect } from 'react';

import useLanguage from './useLanguage';
import useAuth from './useAuth';
import useGQLQuery from './useGQLQuery';
import useConfig from '../_hooks/useConfig';
import { useStore as profileStore } from '../../contexts/profile';

const useProfile = () => {
	const { data: { navigation }, dispatch } = profileStore();
	const { isLanguageLoaded, t } = useLanguage();
	const { userData } = useAuth();
	const { clientConfig } = useConfig();

	useEffect(() => {
		if (navigation === null && isLanguageLoaded) {
			dispatch({
				type: 'SET_NAVIGATION',
				payload: {
					name: 'settings',
					list: [
						{ href: '/profil', text: t('labelAccount') },
						userData.oauth_id ? null : { href: '/profil/uporabnik', text: t('labelAccountProfile') },
						clientConfig('INAPP_NOTIFICATIONS') ? { href: '/profil/obvestila-nastavitve', text: t('labelAccountNotifications') } : null,
						clientConfig('PRO_LOCKED') ? { href: '/profil/placila', text: t('labelAccountPayments') } : null
					]
				}
			});
		}
	}, [isLanguageLoaded]);

	// Query, profile counters
	const { result, error, refetch: getSubmenutCnt } = useGQLQuery(null, 'profile>submenu-cnt', {}, { enabled: false });

	return { navigation, getSubmenutCnt, result, error };
};

export default useProfile;
