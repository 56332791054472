import React, { useEffect } from 'react';
import Link from 'next/link';
import Olark from 'react-olark-plugin';
import CookieConsent from 'react-cookie-consent';
import { useInView } from 'react-intersection-observer';

import styles from './footer.module.scss';
import useLanguage from '../_hooks/useLanguage';
import useApp from '../_hooks/useApp';
import useSiid from '../_hooks/useSiid';
import useConfig from '../_hooks/useConfig';
import Navigation from '../navigation/navigation';
import Toolbar from './toolbar';

const Footer = () => {
	const { t } = useLanguage();
	const { siidNm, footer, socialLinks } = useSiid();
	const { appData: { supportChatOpen, overlayPanel }, appDispatch, appReset } = useApp();
	const { clientConfig } = useConfig();

	const { ref, inView } = useInView({
		threshold: 0,
		triggerOnce: true
	});

	// Olark help
	useEffect(() => {
		window.olark(supportChatOpen ? 'api.box.expand' : 'api.box.hide');
		window.olark('api.box.onShrink', () => appDispatch({ type: 'SUPPORT_CHAT_TOGGLE', payload: false })); // when olark close button is clicked
	}, [supportChatOpen]);

	// <Language />
	// // CookieConsent > acceptOnScroll

	return (
		<div className={`${(overlayPanel && overlayPanel.name) ? `footer-${overlayPanel.name}` : ''} ${styles[siidNm]}`}>
			<CookieConsent
				location="none"
				cookieName="cc-accept"
				buttonText={t('cookies_consent_btn_ok')}
				expires={150}
				disableStyles
			>
				{t('cookies_consent_text')}
				<Link href="/o-nas/politika-zasebnosti-in-varstvo-osebnih-podatkov"><a>{t('btnDetailed')}</a></Link>
			</CookieConsent>
			<Olark
				siteId="6903-235-10-9172"
				systemConfig={{
					hb_primary_color: '#6872E3',
					hb_chatbox_size: 'md',
					hb_show_as_tab: true,
					hb_show_button_text: false
				}}
			/>
			<Toolbar />
			<footer className={styles.default} ref={ref}>
				{inView && (
					<>
						<div className={styles.top}>
							<div className="container">
								<div className="row">
									<div className="col-12 footer-wrapper">
										<div>
											<Navigation list={clientConfig('HELP_ENABLED') ? [...footer.navTop, { href: null, text: t('navSupport'), type: 'button', onClick: () => appDispatch({ type: 'SUPPORT_CHAT_TOGGLE' })}] : footer.navTop} />
										</div>

										{socialLinks && socialLinks.length > 0 && (
											<div>
												<div className={styles.social}>
													{socialLinks.map(row => <a key={row.icon} href={row.href} target="_blank" rel="noopener noreferrer"><span className="hideText">{row.text}</span><i className={`icon ${row.icon}`} /></a>)}													
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className={styles.bottom}>
							<div className="container">
								<div className="row">
									<div className="col-12">
										<Link href="/"><a className={`${styles.logo} hideText`}>Mojedrazbe.com</a></Link>
										<p><span className={styles.reset}>{t('footerCopyrights')}<button type="button" onClick={() => appReset(true)}>reset</button></span></p>
										<Navigation list={footer.navBottom} />
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</footer>
		</div>
	);
};

export default Footer;
